import AnalysisRepository from '@/repositories/AnalysisRepository';
import IAnalysisFilters from '@/types/IAnalysisFilters';
import FileService from '@/services/FileService';
import {IAnalysisChartItem, IAnalysisChartSeries} from '@/types/IAnalysisCharts';
import AnalysisFactory from '@/factories/AnalysisFactory';
import {nullEmptyProps} from '@/utils/universalUtils';

export default class AnalysisService {
  static async getAnalysis(filters: IAnalysisFilters): Promise<{ series: IAnalysisChartSeries[], chartDates: string[] }> {
    const {data} = await AnalysisRepository.get(nullEmptyProps(filters));
    const mappedData = AnalysisFactory.toChartData(data);

    return this.generateSeriesAndDates(mappedData);
  }

  static generateSeriesAndDates(data: IAnalysisChartItem): { series: IAnalysisChartSeries[], chartDates: string[] } {
    const chartDates: string[] = Object.keys(data).sort();

    const results: IAnalysisChartSeries[] = [];

    Object.keys(data).forEach((dateKey) => {
      Object.keys(data[dateKey]).forEach((typeKey) => {
        data[dateKey][typeKey].forEach((item) => {
          const candidate = results.find((result) => result.name === `<b>${typeKey}</b>${item.sku ? `: <i>${item.sku}</i>` : ''}`);
          if (candidate) {
            candidate.data.push(item.value);
          } else {
            results.push({
              name: `<b>${typeKey}</b>${item.sku ? `: <i>${item.sku}</i>` : ''}`,
              data: [item.value],
            });
          }
        });
      });
    });

    return {
      chartDates,
      series: results,
    };
  }

  static async downloadFile(fileType: string, filters: IAnalysisFilters) {
    // const localFilters: IAnalysisFilters = copyObject(filters)
    // localFilters.statusList = ['PendingAvailability']

    const {data} = await AnalysisRepository.download(fileType, filters);

    const file = new FileService(data);
    file.downloadFile('Analiza');
  }

  static async getFilters(): Promise<{ chartTypes: string[], skuList: string[], groupingTypes: string[], marketplaces: object[], countries: object[] }> {
    const {data} = await AnalysisRepository.getFilters();

    return {
      chartTypes: data.chartTypes,
      skuList: data.skuList,
      marketplaces: data.marketplaces,
      groupingTypes: data.groupingType,
      countries: data.countries,
    };
  }
}
