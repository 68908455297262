import {IAnalysisChartItem} from '@/types/IAnalysisCharts';

export default class AnalysisFactory {
  static toChartData(data: any): IAnalysisChartItem {
    const chartData: IAnalysisChartItem = {};
    Object.keys(data).forEach((dateKey: string) => {
      chartData[dateKey] = {};
      Object.keys(data[dateKey]).forEach((typeKey: string) => {
        chartData[dateKey][typeKey] = data[dateKey][typeKey].map((item: any) => {
          return {
            sku: item.sku,
            value: item.value,
          };
        });
      });
    });
    return chartData;
  }
}
