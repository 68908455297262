

import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'AnalysisFilterInfo'})
export default class AnalysisFilterInfo extends Vue {
  @Prop()
  filterName!: string;

  @Prop()
  listItems!: string[];
}
