
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import DebounceInput from '../DebounceInput.vue';
import IAnalysisFilters from '@/types/IAnalysisFilters';
import DatePicker from '@/components/DatePicker.vue';
import CollapseMultiSelect from '@/components/CollapseMultiSelect.vue';
import {GroupingType} from '@/components/enums/AnalysisGroupingTypeEnum';
import {arrayMinLength, minNumber, price, required} from '@/utils/validationRules';
import AnalysisService from '@/services/AnalysisService';
import {capitalize} from '@/utils/universalUtils';
import {resolveError} from '@/utils/notifications';
import AnalysisFilterInfo from '@/components/Analysis/FilterInfo.vue';

@Component({
  name: 'Filters',
  components: {
    AnalysisFilterInfo,
    CollapseMultiSelect,
    DatePicker,
    DebounceInput,
  },
})
export default class Filters extends Vue {
  @Prop()
  filters!: IAnalysisFilters;

  @Prop()
  chartTypes!: string[];

  @Prop()
  skuList!: string[];

  @Prop()
  marketplaces!: object[];

  @Prop()
  countries!: object[];

  @Prop()
  groupingTypes!: string[];

  @Prop()
  loading!: boolean;

  loadingPdf: boolean = false;
  loadingExcel: boolean = false;

  rules = {
    chartTypes: [required, this.validateSkuAndChartTypesFields],
    valueFrom: [price],
    valueTo: [price, this.minNumber],
  };

  groupByOptions: { label: string, type: GroupingType }[] = [
    {
      label: 'Rok',
      type: GroupingType.YEARLY,
    },
    {
      label: 'Miesiąc',
      type: GroupingType.MONTHLY,
    },
    {
      label: 'Tydzień',
      type: GroupingType.WEEKLY,
    },
    {
      label: 'Dzień',
      type: GroupingType.DAILY,
    },
  ];

  isValid: boolean = false;

  async downloadFile(type: string) {
    try {
      // @ts-ignore
      this[`loading${capitalize(type)}`] = true;
      await AnalysisService.downloadFile(type, this.filters);
    } catch (e) {
      resolveError(e, 'download');
    } finally {
      // @ts-ignore
      this[`loading${capitalize(type)}`] = false;
    }
  }

  applyFilters() {
    this.$emit('applyFilters');
  }

  validateSkuAndChartTypesFields(value: string[]) {
    return arrayMinLength(value, 1) === true || 'Pole jest wymagane';
  }

  minNumber(value: number | string) {
    return minNumber(value, Number(this.filters.valueFrom));
  }

  @Emit('changed')
  changed() {
    // @ts-ignore
    this.isValid = this.$refs.form.validate();
    return this.filters;
  }
}
