
import {Component, Vue} from 'vue-property-decorator';
import IAnalysisFilters from '@/types/IAnalysisFilters';
import {dateToYYYYmmDD, startMonthDate, subtractMonths} from '@/utils/dateTime';
import AnalysisService from '@/services/AnalysisService';
import {resolveError} from '@/utils/notifications';
import {IAnalysisChartSeries} from '@/types/IAnalysisCharts';
import AnalysisChart from '@/components/Analysis/AnalysisChart.vue';
import AnalysisFilters from '@/components/Analysis/Filters.vue';

@Component({
  name: 'Analyses',
  components: {
    AnalysisChart,
    AnalysisFilters,
  },
})
export default class Analyses extends Vue {
  filters: IAnalysisFilters = {
    sku: [],
    chartTypes: [],
    marketplaceId: [],
    country: [],
    valueFrom: null,
    valueTo: null,
    groupingType: null,
    timezone: 0,
    dateFrom: startMonthDate(subtractMonths(new Date(), 3)),
    dateTo: dateToYYYYmmDD(new Date()),
  };

  loading: boolean = false;

  chartTypes: string[] = [];
  skuList: string[] = [];
  marketplaces: object[] = [];
  countries: object[] = [];
  groupingTypes: string[] = [];

  chartDates: string[] = [];
  series: IAnalysisChartSeries[] = [];

  created() {
    this.fetchAnalysesFiltersData();
  }

  async fetchAnalysesFiltersData() {
    try {
      const {
        chartTypes,
        skuList,
        marketplaces,
        countries,
        groupingTypes,
      } = await AnalysisService.getFilters();
      this.chartTypes = chartTypes;
      this.skuList = skuList;
      this.marketplaces = marketplaces;
      this.countries = countries;
      this.groupingTypes = groupingTypes;

      this.filters.groupingType = this.groupingTypes[0];
    } catch (e) {
      resolveError(e, 'fetch_filters');
    }
  }

  async fetchData() {
    try {
      this.loading = true;
      const {
        chartDates,
        series,
      } = await AnalysisService.getAnalysis(this.filters);
      this.chartDates = chartDates;
      this.series = series;
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false;
    }
  }

  onFiltersChanged(filters: IAnalysisFilters) {
    this.filters = {...filters};
  }
}
